import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from 'components/layout';
// import Img from 'gatsby-image';
import { LoadingClass, Quick, RespCheck } from 'utils/common';
import ImgTopArrow from '../img/arrow_top_circle.svg';
import { Helmet } from 'react-helmet';
import { FitWidthClass } from 'utils/fitwidth';

export default class WorksLanding extends React.Component {
  fitwidth = null;
  Loading = new LoadingClass({
    PostLoaded: () => {
      this.fitwidth = new FitWidthClass({ target: '.each_work' });
    }
  });
  componentDidMount() {
    this.Loading.dataLoaded = true;
    this.Loading.Load();
    if (typeof document !== `undefined`)
      document.body.classList.remove('fromwork');
    WorksFunction.init();

    if (typeof document !== `undefined`) {
			document.body.id = 'works';
			window.addEventListener('resize', this.resize, false);
    }
  }
  componentWillUnmount() {
    WorksFunction.exit();
    this.Loading.Exit();
    if (this.fitwidth !== null) {
      this.fitwidth.kill();
    }
    if (typeof window !== `undefined`) {
			window.removeEventListener('resize', this.resize, false);
    }
	}
	resize = () => {
		if (RespCheck.width.mobile()) {
      this.fitwidth.toggle(false);
		}else{
      this.fitwidth.toggle(true);
		}
	}
  gotoWorks() {
    if (typeof document !== `undefined`) {
      document.body.classList.add('fromwork');
      Quick.qS('#WorksBg').classList.add('on');
    }
  }
  render() {
    this.Loading.MinTimeLoad();
    const data = this.props.data.all;
    const works = this.props.data.works;
    const seo = this.props.data.works.frontmatter.seo;

    return (
      <Layout titleText={works.frontmatter.title} mainId='works'>
        <Helmet>
          {seo.seo_shortdesc && (
            <meta name='description' content={seo.seo_shortdesc} />
          )}
          {seo.seo_image && <meta name='image' content={seo.seo_image} />}
          {seo.seo_keywords && (
            <meta name='keywords' content={seo.seo_keywords} />
          )}
          {seo.seo_shortdesc && (
            <meta property='og:description' content={seo.seo_shortdesc} />
          )}
          {seo.seo_image && (
            <meta property='og:image' content={seo.seo_image} />
          )}
          {seo.seo_shortdesc && (
            <meta name='twitter:description' content={seo.seo_shortdesc} />
          )}
          {seo.seo_image && (
            <meta name='twitter:image' content={seo.seo_image} />
          )}
        </Helmet>
        <section className='full'>
          <div id='WorkContainer'>
            {data.edges.map(({ node }) => (
              <div className='each_work' key={node.id}>
                <Link to={`/${node.fields.slug}`} onClick={this.gotoWorks}>
                  <h3>{node.frontmatter.title}</h3>
                </Link>
                <span>{node.frontmatter.tag}</span>
              </div>
            ))}
          </div>
        </section>

        <div id='btt'>
          <img src={ImgTopArrow} alt='MALT Studio Button' />
        </div>
      </Layout>
    );
  }
}

const WorksFunction = {
  init: () => {
    WorksFunction.mouseover();
    WorksFunction.setBG(-1);

    WorksFunction.scrollinit();
    WorksFunction.checkBTT();
    Quick.qS('#btt').addEventListener(
      'click',
      e => {
        if (typeof document !== `undefined`)
          Quick.ScrollIt(
            document.body,
            WorksFunction.scrollspeed,
            'easeInOutQuad'
          );
      },
      { passive: true }
    );

    const bgContainer = Quick.qS('#WorksBg');
    bgContainer.classList.remove('on');
  },
  scrollspeed: 500,
  exit: () => {
    WorksFunction.mouseoverremove();
    WorksFunction.scrollremove();
    Quick.qS('#btt').removeEventListener(
      'click',
      e => {
        if (typeof document !== `undefined`)
          Quick.ScrollIt(
            document.body,
            WorksFunction.scrollspeed,
            'easeInOutQuad'
          );
      },
      { passive: true }
    );
  },
  mouseover: () => {
    const allWorks = Quick.qSAll('.each_work');
    const container = Quick.qS('#WorkContainer');
    const bgContainer = Quick.qS('#WorksBg');
    if (!('ontouchstart' in document.documentElement)) {
      container.addEventListener(
        'mouseover',
        e => {
          bgContainer.classList.add('on');
        },
        { passive: true }
      );
      container.addEventListener(
        'mouseout',
        e => {
          bgContainer.classList.remove('on');
        },
        { passive: true }
      );
      allWorks.forEach((work, index) => {
        work.addEventListener(
          'mouseover',
          e => {
            WorksFunction.setBG(index);
          },
          { passive: true }
        );
      });
    }
  },
  mouseoverremove: () => {
    const allWorks = Quick.qSAll('.each_work');
    const container = Quick.qS('#WorkContainer');
    const bgContainer = Quick.qS('#WorksBg');
    if (!('ontouchstart' in document.documentElement)) {
      container.removeEventListener(
        'mouseover',
        e => {
          bgContainer.classList.add('on');
        },
        { passive: true }
      );
      container.removeEventListener(
        'mouseout',
        e => {
          bgContainer.classList.remove('on');
        },
        { passive: true }
      );
      allWorks.forEach((work, index) => {
        work.removeEventListener(
          'mouseover',
          e => {
            WorksFunction.setBG(index);
          },
          { passive: true }
        );
      });
    }
  },
  setBG: (n = -1) => {
    const allBg = Quick.qSAll('#WorksBg > div');
    allBg.forEach((bg, index) => {
      bg.classList.remove('active');
    });
    if (n >= 0) {
      allBg[n].classList.add('active');
    }
  },
  scrollinit: () => {
    if (typeof window !== `undefined`) {
      window.addEventListener('scroll', WorksFunction.checkBTT, {
        passive: true
      });
    }
  },
  scrollremove: () => {
    if (typeof window !== `undefined`) {
      window.removeEventListener('scroll', WorksFunction.checkBTT, {
        passive: true
      });
    }
  },
  checkBTT: () => {
    if (typeof window !== `undefined`) {
      if (window.scrollY > 50) {
        Quick.qS('#btt').classList.remove('hide');
      } else {
        Quick.qS('#btt').classList.add('hide');
      }
    }
  }
};

export const query = graphql`
  query($slug: String!) {
    works: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        seo {
          seo_shortdesc
          seo_keywords
          seo_image {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
    all: allMarkdownRemark(
      filter: {
        frontmatter: { issetting: { eq: false }, contenttype: { eq: "work" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            tag
            year
            date
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;
