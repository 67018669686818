export class FitWidthClass {
  enable = true;
  constructor(obj) {
    if (obj) {
      if (typeof obj.target === 'string') {
        this.targetquery = obj.target;
      }
      if (window.addEventListener) {
        window.addEventListener('resize', this.resize, false);
      }
      this.fitcontents();
    }
  }
  fitcontents = () => {
    if (this.targetquery) {
      if (this.enable) {
        const targets = document.querySelectorAll(this.targetquery);

        targets.forEach(target => {
          // const target = targets[0];
          if (target) {
            // GET MIN HEIGHT
            target.style.overflowX = 'hidden';
            target.style.width = null;
            target.style.whiteSpace = 'nowrap';
            let minHeight = target.offsetHeight;
            target.style.whiteSpace = null;

            // GET OTHER VARIABLES
            let initialW = target.offsetWidth;
            let currentW = initialW;
            let initialH = target.offsetHeight;
            let targetH = initialH;

            // SLOWLY REDUCE THE WIDTH;
            let loop = true;
            if (initialH <= minHeight) {
              // loop = false;
            }
            while (loop) {
              if (currentW > 5) {
                currentW--;
                target.style.width = `${currentW}px`;
                targetH = target.offsetHeight;
                if (targetH > initialH) {
                  loop = false;
                }
              } else {
                currentW = target.querySelector('a').scrollWidth;
                loop = false;
              }
              // console.log(currentW,target.scrollWidth,targetH,initialH, loop);
            }
            target.style.overflowX = null;
            target.style.width = null;
            if (currentW < initialW && currentW > 5) {
              const setW = currentW + 1;
              target.style.width = `${setW}px`;
            } else {
              target.style.width = null;
            }
            // console.log(currentW,target.scrollWidth,targetH,initialH, loop);
          }
        });
      } else {
        const targets = document.querySelectorAll(this.targetquery);
        targets.forEach(target => {
          // const target = targets[0];
          if (target) {
            // GET MIN HEIGHT
            target.style.overflowX = null;
            target.style.width = null;
            target.style.whiteSpace = null;
          }
        });
      }
    }
  };
  resize = () => {
    this.fitcontents();
  };
  toggle = enable => {
    this.enable = enable;
  };
  kill() {
    if (window.addEventListener) {
      window.removeEventListener('resize', this.resize, false);
    }
  }
}
